import type { FormatterMap, RouteList } from '@grandvisionhq/frk-routing'
import { createSimpleFormatter } from '@grandvisionhq/frk-routing'
import {
  createAuthFormatters,
  createCheckoutFormatters,
  createMyAccountFormatters,
  productDetailFormatters,
} from '@grandvisionhq/www-next/formatters'

export const localRouteFormatters: FormatterMap = {
  home: createSimpleFormatter('/'),
  'cart.overview': createSimpleFormatter('cart'),
  'stores.overview': createSimpleFormatter('opticien'),
  'appointments.book': createSimpleFormatter('prendre-rendez-vous'),
  'appointments.cancel': createSimpleFormatter('cancel-appointment'),
  'appointments.reschedule': createSimpleFormatter('reschedule-appointment'),
  ...createMyAccountFormatters(),
  ...createAuthFormatters(),
  'express-checkout': createSimpleFormatter('express-checkout'),
  'order.confirm': createSimpleFormatter('order/confirm'),
  ...createCheckoutFormatters({
    path: 'checkout',
    children: {
      login: 'login',
      shipping: 'livraison',
      overview: 'recapitulatif',
      payment: 'paiement',
    },
  }),
  ...productDetailFormatters({
    accessories: 'accessoires',
    'contact-lenses': 'lentilles',
    frames: 'lunettes-de-vue',
    'ready-readers': 'lunettes-premontees',
    solutions: 'lentilles/entretien',
    sunglasses: 'lunettes-de-soleil',
  }),
  wishlist: createSimpleFormatter('/wishlist'),
}

export const routes: RouteList = [
  ['/', 'home'],

  ['/checkout/login', 'checkout.login'],
  ['/checkout/livraison', 'checkout.shipping'],
  ['/checkout/recapitulatif', 'checkout.overview'],
  ['/checkout/paiement', 'checkout.payment'],
  ['/api/payment-callback', 'payment.callback'],
  ['/order/confirm', 'order.confirm'],

  ['/prendre-rendez-vous/(.*)?', 'appointments.book'],
  ['/modifier-rendez-vous', 'appointments.reschedule'],
  ['/annuler-rendez-vous', 'appointments.cancel'],
]
