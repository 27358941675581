import type { AppointmentFormTypes as Types } from '@grandvisionhq/appointments'

export const consents: Types.ConsentOptIn[] = [
  {
    title: 'appointments.consent.createAccount',
    value: 'createAccount',
    required: true,
    children: [
      {
        title: 'appointments.optIn.marketingEmails',
        value: 'subscribe-newsletter',
      },
      {
        title: 'appointments.optIn.directMail',
        value: 'direct-mail',
      },
      {
        title: 'appointments.optIn.marketingSMS',
        value: 'marketing-sms',
      },
    ],
  },
]

export const optIns: Types.ConsentOptIn[] = []
