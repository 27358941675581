import { Config } from '@grandvisionhq/shipping'

const config: Config = {
  inventoryChannels: {
    // There is a bug in using the key as the cart does not return inventory channel keys.
    // test
    '6a3af3f8-1d0a-460e-94e3-93dbcb8c7916': {
      expectedDeliveryDays: {
        onStock: '2-3',
        outOfStock: '7',
      },
    },
    // acc
    'f90d489a-a794-441e-b151-02d0dc51ad6d': {
      expectedDeliveryDays: {
        onStock: '2-3',
        outOfStock: '7',
      },
    },
    // prod
    '2f1f1655-0aeb-4f03-adb7-16b3e28e42c1': {
      expectedDeliveryDays: {
        onStock: '2-3',
        outOfStock: '7',
      },
    },
  },
  missingInventoryChannelStrategy: {
    expectedDeliveryDays: '8',
  },
  nextDayDeliveryTimeLimit: '17:30',
}

export const getDeliveryIndicatorConfig = () => config
