import { Types } from '@grandvisionhq/locations'

/*
 * Address formatting configuration
 *
 * Each format (MINIMAL, SMALL, MEDIUM, LARGE, COMPLETE), represents a
 * list of rows. Rows can be injected with values on places written like: {<valueName>}.
 * Non-word characters and spaces that remain at the end of a row after injection
 * will be trimmed and removed.
 */
const config: Types.AddressConfiguration = {
  MINIMAL: ['{streetNumber} {streetName} {postalCode} {town}'],
  SMALL: ['{streetNumber} {streetName} {postalCode} {town}'],
  MEDIUM: ['{streetNumber} {streetName} {additionalStreetInfo} {postalCode} {town}'],
  LARGE: ['{streetNumber} {streetName} {additionalStreetInfo} {postalCode} {town}'],
  COMPLETE: ['{streetNumber} {streetName}', '{additionalStreetInfo}', '{postalCode} {town}'],
}

export const getAddressConfig = () => config
