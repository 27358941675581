import { Countries, Currencies, DistanceUnit, IntlSettings, Locales } from '@grandvisionhq/state'

export const INTL_DEFAULTS: IntlSettings = {
  country: Countries.FR,
  locale: Locales['fr-FR'],
  currency: Currencies.EUR,
  distanceUnit: DistanceUnit.km,
  dateFormat: {
    short: 'dd/MM/yyyy',
    midsize: 'dd MMMM yyyy',
    long: 'eeee d MMMM, yyyy', //Mercredi 28 septembre, 2022
  },
}

type PossibleLocales = keyof Pick<typeof Locales, 'fr-FR'>

export const intlConfigMap: {
  [L in PossibleLocales]: IntlSettings
} = {
  'fr-FR': INTL_DEFAULTS,
}
